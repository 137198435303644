import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  googleMapInitialized: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  url =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyDULt80JLw1p5mA83rAvdB_cWtEiCQr7uM?callback=JSONP_CALLBACK';

  constructor(private http: HttpClient) {}

  initializeGoogleMaps() {
    let id = 'google-maps-markup';
    let url =
      'https://maps.google.com/maps/api/js?key=AIzaSyDULt80JLw1p5mA83rAvdB_cWtEiCQr7uM&libraries=geometry,places&language=en';

    let promise = new Promise((resolve, reject) => {
      // setTimeout(() => {
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        // if (c) {
        script.addEventListener(
          'load',
          function (e) {
            // c(null, e);
            resolve(true);
          },
          false
        );
        // }
        document.head.appendChild(script);
      } else {
        resolve(true);
      }
      // }, 5000);
    });
    return promise;
  }

  getAddressFromLatLng(coords: any) {
    let latLng = `${coords.lat},${coords.lng}`;
    // return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=AIzaSyD7ekf6rQBZq71qNidHpgrEQ0JLToxAIh0`)
    // .pipe(
    //   map((data) => {
    //     console.log("data", data);
    //   }),
    //   catchError(() => of(false)),
    // );
    // return this.http.get(environment.apiUrl + this.url + '?', {params});
  }
}
