<div style="display:flex; flex-direction: column; min-height: 100vh; position:relative;">
  <router-outlet></router-outlet>
  <app-loader></app-loader>
</div>


<val-default-errors>
  <ng-template valError="required" let-label>{{ label || 'This field' }} is required</ng-template>
  <ng-template valError="email" let-label>{{ label || 'This field' }} must be a valid email address</ng-template>
  <ng-template valError="min" let-label let-error="error">{{ label || 'This field' }} must be greater
    that {{error.min}}</ng-template>
  <ng-template valError="max" let-label let-error="error">{{ label || 'This field' }} must be less
    that {{error.max}}</ng-template>
  <ng-template valError="minlength" let-label let-error="error">{{ label || 'This field' }} must contains at
    least {{error.requiredLength}} chars
  </ng-template>
  <ng-template valError="password_mismatch" let-label let-error="error">{{ label || 'This field' }} must match
    password
  </ng-template>
  <ng-template valError="pattern" let-label let-error="error">{{ label || 'This field' }} must match pattern
  </ng-template>
</val-default-errors>
