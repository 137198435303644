import {Component, Injector} from '@angular/core';
import { BaseService } from './services/base.service';
import {ContextController} from "./services/context-controller";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ContextController {
  title = 'BMA Roots & Routes';

  constructor(private injector: Injector,
              private baseService: BaseService) {
    super(injector);

    if(this.currentContext?.name=== this.MODES.RECORD?.name) {
      if(window.location.pathname !== this.MODES.RECORD?.startPath) {
        window.location.href = this.currentContext.startPath;
      }
    }

    this.baseService
      .initializeGoogleMaps()
      .then((data: any) => {
        console.log('Google Map Initialized', data);
        // setTimeout(() => {
        this.baseService.googleMapInitialized.next(data);
        // }, 1000);
      })
      .catch((error) => {
        console.log('Google Map NOT Initialized!', error);
      });
  }
}
