import {
  AfterViewInit,
  Component,
  HostListener, Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {ContextController} from "../../services/context-controller"

declare var WE: any;
declare var earth: any;

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent extends ContextController implements OnInit, AfterViewInit, OnDestroy {
  earth: any = null;
  isAnimated = false;

  constructor(
    private injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (document.hasFocus()) {
        this.isAnimated = true;
        this.initialize();
      }
    });
  }

  init() {
    this.earth = new WE.map('earth_div');
    this.earth.setMinAltitude(250000);
    this.earth.setMaxAltitude(10000000);
    this.earth.setView([37.715736, -125.161087], 5);
    WE.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '© OpenStreetMap contributors',
    }).addTo(this.earth);
  }

  initialize() {
    if (!this.earth) {
      this.init();
    }
    const animate = (now: any) => {
      if (!this.isAnimated) {
        return;
      }
      let c: any = this.earth.getPosition();
      this.earth.setCenter([c[0], c[1] + 0.1 * (40 / 60)]);
      setTimeout(() => {
        requestAnimationFrame(animate);
      }, 40);
    };
    requestAnimationFrame(animate);
  }

  @HostListener('window:focus', ['$event'])
  focusHandler() {
    if (!this.isAnimated) {
      this.isAnimated = true;
      this.initialize();
    }
  }

  @HostListener('window:blur', ['$event'])
  blurHandler() {
    if (this.isAnimated) {
      this.isAnimated = false;
    }
  }

  goToPage() {
    if (this.currentContext === this.MODES.FULL || this.currentContext === this.MODES.EXPLORE) {
      window.location.href = `/stories`;
    }
    else if (this.currentContext === this.MODES.RECORD) {
      window.location.href = `/tell`;
    }
  }

  ngOnDestroy() {
    this.earth = null;
    this.isAnimated = false;
  }
}
